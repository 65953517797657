

import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { createProvider } from "./vue-apollo";
import { lettre } from "./utils/Lettre";

import IdleVue from "idle-vue";


Vue.config.productionTip = false;
Vue.prototype.$lettre = lettre;
const eventsHub = new Vue({
  router,
  store,
  vuetify,
  Vuex,
  apolloProvider: createProvider(),
  render: (h) => h(App),

}).$mount("#app");
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 600000, // 10 minutes
  startAtIdle: false
});
