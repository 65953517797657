//import { from } from 'core-js/fn/array'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { isLoggedIn } from "../vue-apollo.js"

Vue.use(VueRouter)

const routes = [
  {
    path: '/', component: () => import('../views/Login.vue'),
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/',
    component: () => import('../views/MainLayout.vue'),

    children: [      
      { path: '/dashboard', component: () => import('../views/Dashboard.vue') },
      { path: '/flash', component: () => import('../views/PProduct.vue') },
      { path: '/about', component: () => import('../views/About.vue') },
      { path: '/documents',name:"documents",  component: () => import('../views/ListeDocuments.vue') },
      { path: '/groups',name:"groups",  component: () => import('../views/ListeGroupes.vue') },
      { path: '/tiers', component: () => import('../views/ListeTiers.vue') ,  props: { tiertype: 0 } },
      { path: '/fournisseurs',name:"fournisseurs", component: () => import('../views/ListeTiers.vue') ,  props: { tiertype: 1 }},
      { path: '/clients',name:"clients", component: () => import('../views/ListeTiers.vue') , props: { tiertype: 2 }},  
      { path: '/da',name:"da", component: () => import('../views/ListeDemandes.vue'), props: { dem_type: 1 } }, 
      { path: '/offre',name:"offre", component: () => import('../views/ListeDemandes.vue'), props: { dem_type: 2 } },       
      { path: '/consult',name:"consult", component: () => import('../views/ListeConsultations.vue') },    

      { path: '/imports',name:"imports", component: () => import('../views/ListeImports.vue') },     
      { path: '/products_02',name:"product_tobuy", component: () => import('../views/ListeProduits.vue') , props: { prd_type: "02" } },
      { path: '/products_03',name:"product_tosell", component: () => import('../views/ListeProduits.vue') , props: { prd_type: "03" } },
      { path: '/products_04',name:"product_04", component: () => import('../views/ListeProduits.vue') , props: { prd_type: "04" } },
      { path: '/products_34',name:"product_34", component: () => import('../views/ListeProduits.vue') , props: { prd_type: "34" } },

      { path: '/encours_p',name:"encours_p", component: () => import('../views/ListeEncours.vue') ,props: { prd_type: "p" }},
      { path: '/encours_i',name:"encours_i", component: () => import('../views/ListeEncours.vue') ,props: { prd_type: "i" }},
      
      { path: '/dictionnaire', component: () => import('../views/Dictionnaire.vue') , },

      { path: '/proformas_c',name:"proformas_c",  component: () => import('../views/ListeProformas.vue') , props: { tiertype: 1 }},
      { path: '/proformas_f',name:"proformas_f",  component: () => import('../views/ListeProformas.vue') , props: { tiertype: 2 }},

      //{ path: '/depotslist',  component: () => import('../views/Listedepots.vue') },
      { path: '/inventaires',  component: () => import('../views/Inventaires.vue') },

      { path: '/items_expire_mp',name:"items_expire_mp",  component: () => import('../views/ListeExpired.vue'), props: { prd_type: "34" } },
      { path: '/items_expire_pf',name:"items_expire_pf",  component: () => import('../views/ListeExpired.vue'), props: { prd_type: "03" } },

      { path: '/receptions_i',name:"receptions_i",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 6 } },
      { path: '/bsm',name:"bsm",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 7 } },      
      { path: '/brm',name:"brm",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 8 } },      
      { path: '/bof',name:"bof",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 9 } },
      { path: '/receptions_nc',name:"receptions_nc",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 14 } },
      { path: '/bem',name:"bem",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 18 } },
      { path: '/bep',name:"bep",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 19 } },
      { path: '/br',name:"br",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 10 } },
      { path: '/bcs',name:"bcs",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 11 } },
      { path: '/bce',name:"bce",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 12 } },
      { path: '/transferts',name:"transferts",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 5 }},
      { path: '/bsmg',name:"bsmg",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 15 } },
      { path: '/brmg',name:"brmg",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 16 } },
      { path: '/bsmp',name:"bsmp",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 21 } },
      { path: '/brmp',name:"brmp",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 22 } },


      { path: '/consom',name:"consom",  component: () => import('../views/SuiviConsommation.vue') },

      { path: '/receptions_e',name:"receptions_e",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 1 } },      
      { path: '/retours_f',name:"retours_f",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 2 } },
      { path: '/livraisons',name:"livraisons",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 3 } },
      { path: '/retours_c',name:"retours_c",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 4 } },
      
      
      { path: '/service_fait_a',name:"service_fait_a",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 17 } },
      { path: '/service_fait_v',name:"service_fait_v",  component: () => import('../views/ListeMvmsPage.vue') , props: { mvm_type: 23 } },

      { path: '/colis',name:"colis",  component: () => import('../views/ListeColis.vue')},
            
      { path: '/facturations_f',name:"facturations_f",  component: () => import('../views/ListeFactures.vue') , props: { fact_type: 1 } },
      { path: '/facturations_fa',name:"facturations_fa",  component: () => import('../views/ListeFactures.vue') , props: { fact_type: 2 } },
      { path: '/facturations_c',name:"facturations_c",  component: () => import('../views/ListeFactures.vue') , props: { fact_type: 3 } },
      { path: '/facturations_ca',name:"facturations_ca",  component: () => import('../views/ListeFactures.vue') , props: { fact_type: 4 } },

      { path: '/dps_fournis',name:"dps_fournis",  component: () => import('../views/ListeDPs.vue'), props: { dp_cat: 1 }  },
      { path: '/dps_clients',name:"dps_clients",  component: () => import('../views/ListeDPs.vue'), props: { dp_cat: 4 }  },
      { path: '/dps_compta',name:"dps_compta",  component: () => import('../views/ListeDPs.vue'), props: { dp_cat: 5 }  },

      { path: '/suivi_dp',name:"suivi_dp",  component: () => import('../views/SuiviDps.vue')  },
      { path: '/suivi_factures_c',name:"suivi_factures_c",  component: () => import('../views/SuiviFactures.vue') , props: { fact_type: 3 } },
      { path: '/suivi_factures_f',name:"suivi_factures_f",  component: () => import('../views/SuiviFactures.vue') , props: { fact_type: 1}},      

      { path: '/suivi_cauts_f',name:"suivi_cauts_f",  component: () => import('../views/ListeCautions.vue') , props: { tiertype: 2 } },
      { path: '/suivi_cauts_c',name:"suivi_cauts_c",  component: () => import('../views/ListeCautions.vue') , props: { tiertype: 1 } },
      
      { path: '/suivi_bls',name:"suivi_bls",  component: () => import('../views/SuiviBls.vue') , props: { mvm_type: 3 } },
      { path: '/suivi_rcpts',name:"suivi_rcpts",  component: () => import('../views/SuiviBls.vue') , props: { mvm_type: 1 } },

      { path: '/paiements',name:"paiements",  component: () => import('../views/ListePaiements.vue') , props: { paye_type: 1 } },
      { path: '/encaissements',name:"encaissements",  component: () => import('../views/ListePaiements.vue') , props: { paye_type: 3 } },
      
      { path: '/stock_v',name:"stock_v",  component: () => import('../views/ListeProductDepot.vue') , props: { prd_type: "03" } },
      { path: '/stock_gsm',name:"stock_gsm",  component: () => import('../views/ListeProductDepot.vue') , props: { prd_type: "34" } },
      { path: '/stock_p',name:"stock_p",  component: () => import('../views/ListeProductDepot.vue') , props: { prd_type: "04" } },
      { path: '/stock_nc',name:"stock_nc",  component: () => import('../views/ListeProductDepot.vue') , props: { prd_type: "05" } },
            
      { path: '/suivi_cmds_c',name:"suivi_cmds_c",  component: () => import('../views/SuiviCommandes.vue') , props: { tiertype: 1 } },
      { path: '/suivi_cmds_f',name:"suivi_cmds_f",  component: () => import('../views/SuiviCommandes.vue') , props: { tiertype: 2 } },
      { path: '/suivi_contrats_c',name:"suivi_contrats_c",  component: () => import('../views/SuiviCommandes.vue') , props: { tiertype: 3 } },
      { path: '/suivi_contrats_f',name:"suivi_contrats_f",  component: () => import('../views/SuiviCommandes.vue') , props: { tiertype: 4 } },

      { path: '/sit_cmds_a',name:"sit_cmds_a",  component: () => import('../views/SuiviDossier.vue') , props: { tiertype: 2 } },
      { path: '/sit_cmds_v',name:"sit_cmds_v",  component: () => import('../views/SuiviDossier.vue') , props: { tiertype: 1 } },

      { path: '/sit_fournisseurs',name:"sit_fournisseurs",  component: () => import('../views/Sit_Tiers.vue') , props: { tiertype: 1 } },
      { path: '/sit_clients',name:"sit_clients",  component: () => import('../views/Sit_Tiers.vue') , props: { tiertype: 2 } },

      { path: '/commandes_c',name:"commandes_c",  component: () => import('../views/ListeCommandes.vue') , props: { tiertype: 1  } },
      { path: '/commandes_f',name:"commandes_f",  component: () => import('../views/ListeCommandes.vue') , props: { tiertype: 2  } },      

      { path: '/contrats_c',name:"contrats_c",  component: () => import('../views/ListeCommandes.vue') , props: { tiertype: 3  } },
      { path: '/contrats_f',name:"contrats_f",  component: () => import('../views/ListeCommandes.vue') , props: { tiertype: 4  } }, 
      
      { path: '/convention_c',name:"convention_c",  component: () => import('../views/ListeCommandes.vue') , props: { tiertype: 5  } },
      { path: '/convention_f',name:"convention_f",  component: () => import('../views/ListeCommandes.vue') , props: { tiertype: 6  } },

      { path: '/orders_p',name:"orders_p",  component: () => import('../views/ListeOrders.vue'), props: { code_app: 4 } },
      { path: '/orders_v',name:"orders_v",  component: () => import('../views/ListeOrders.vue'), props: { code_app: 3 } },
      { path: '/fiches',name:"fiches",  component: () => import('../views/ListeFiches.vue') ,props: { transf: false }},
      { path: '/transf',name:"transf",  component: () => import('../views/ListeFiches.vue') ,props: { transf: true }},
      { path: '/bts',name:"bts",  component: () => import('../views/ListeBons.vue') },
      
      { path: '/categories',name:"categories",  component: () => import('../views/Categories.vue'), props: { cat_type: 1 } },
      { path: '/categories_documents',name:"categories_documents",  component: () => import('../views/Categories.vue'), props: { cat_type: 4 } },
      
      { path: '/structures',  component: () => import('../views/Structures.vue') ,props: { structtype: "s" } },
      { path: '/depotslist',  component: () => import('../views/Structures.vue') ,props: { structtype: "d" } },
      { path: '/attributs',  component: () => import('../views/ListeAttributs.vue') },
      { path: '/gammes',  component: () => import('../views/ListeGammes.vue')  },
      { path: '/arrets',  component: () => import('../views/ListeArret.vue')  },
      { path: '/agents',  component: () => import('../views/ListeAgents.vue')  },
      { path: '/simulateur',  component: () => import('../views/Simulateur.vue')  },
      { path: '/process',name:"process",  component: () => import('../views/ListeProcess.vue')  },      
      { path: '/machines',  component: () => import('../views/ListeMachines.vue')  },      
      { path: '/modeles',  component: () => import('../views/ListeTemplates.vue') },
      { path: '/devis/:id', name:"devis", component: () => import('../views/Devis.vue') ,params: true}, 
      { path: '/users',  component: () => import('../views/Users.vue') },     
      { path: '/auth', component: () => import('../components/NotAuth.vue') },
      { path: '/network_error', name:"network_error", component: () => import('../components/NetworkError.vue') },
      { path: '*', component: () => import('../components/NotFound.vue') },

    ]
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next({
      path: '/',
      query: { redirect: to.fullPath }
    })
  }
  else {
    next()
  }
})


export default router
